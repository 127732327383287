<template>
  <Transition name="banner">
    <div
      v-if="shouldShowBanner"
      data-testid="account-protection-banner"
      class="banner"
    >
      <div class="banner__content">
        <DSIconUserLock class="banner__icon" />
        <div class="banner__text">
          <h2 class="banner__title">{{ t('title') }}</h2>
          <p class="banner__description">{{ t('description') }}</p>
        </div>
      </div>
      <div class="banner__actions">
        <DSButton
          variant="text"
          class="banner__more-info"
          @click="handleModalOpen"
        >
          {{ t('button_text') }}
        </DSButton>
        <DSIconButton
          class="banner__close"
          size="small"
          hide-tooltip
          @click="handleBannerClose"
        >
          <DSIconClose class="banner__close-icon" />
        </DSIconButton>
      </div>
    </div>
  </Transition>

  <AccountProtectionModal v-if="shouldShowModal" @close="handleModalClose" />
</template>

<script setup lang="ts">
import {
  DSButton,
  DSIconUserLock,
  DSIconClose,
  DSIconButton,
} from '@prolific-oss/design-system'
import { useI18n } from 'vue-i18n'
import AccountProtectionModal from './AccountProtectionModal.vue'
import { useBanner, useModal } from './composables'

const { t } = useI18n()
const { shouldShowBanner, handleBannerClose } = useBanner()
const { shouldShowModal, handleModalOpen, handleModalClose } = useModal()
</script>

<i18n lang="yaml">
en:
  title: 'Protect your account'
  description: 'Enable multifactor authentication to prevent unauthorized access to your account.'
  button_text: 'More info'
</i18n>

<style lang="scss" scoped>
.banner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--color-primary-50);
  padding: var(--space-4);
  box-sizing: border-box;
  position: sticky !important;
  top: 0 !important;
  z-index: 1 !important;

  &__content,
  &__actions {
    display: flex;
    align-items: flex-start;
  }

  &__content {
    flex-grow: 1;
    margin-right: var(--space-4);
  }

  &__icon,
  &__close {
    color: var(--color-primary-700);
    flex-shrink: 0;
  }

  &__icon {
    margin-right: var(--space-2);
    margin-top: var(--space-1);
  }

  &__text {
    display: flex;
    flex-direction: column;
  }

  &__title,
  &__description {
    color: var(--color-primary-700);
    margin: 0;
    font-size: var(--font-size-8);
  }

  &__title {
    font-weight: var(--font-weight-600);
    margin-bottom: var(--space-1);
  }

  &__more-info,
  &__close {
    cursor: pointer;
    transition: all var(--transition-base);

    &:hover:not(:disabled),
    &:focus-visible {
      box-shadow: var(--box-shadow-hover);
    }

    &:focus-visible {
      outline: 0;
    }

    &:active {
      transition: none;
      box-shadow: none;
    }
  }

  &__more-info {
    color: var(--color-primary-700);
    font-weight: var(--font-weight-600);
    margin-right: var(--space-2);
    text-decoration: underline;
  }

  &__close {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: var(--border-radius-sm);
    flex-shrink: 0;

    &-icon {
      transition: transform var(--transition-base);
      width: 100%;
      height: 100%;
      font-size: var(--space-4);
    }

    &:hover:not(:disabled) {
      background-color: color-mix(
        in srgb,
        var(--color-primary-700) 5%,
        transparent
      );

      .banner__close-icon {
        transform: scale(1.1);
      }
    }

    &:active {
      background-color: color-mix(
        in srgb,
        var(--color-primary-700) 10%,
        transparent
      );
    }
  }
}

:deep(.ds-icon-button-wrapper) {
  .button.ds-icon-button.banner__close {
    width: var(--space-4);
    height: var(--space-4);
    position: relative;
  }
}

.banner-enter-active,
.banner-leave-active {
  transition:
    opacity 0.5s ease,
    transform 0.5s ease;
}

.banner-enter-from,
.banner-leave-to {
  opacity: 0;
  transform: translateY(calc(-1 * var(--space-5)));
}
</style>
