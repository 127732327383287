import type { Workspace } from '@/api/types'
import { i18n } from '@/i18n'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_INC_AI_WORKSPACE_LANGUAGE } from '@/integrations/launchDarkly/active-flags'

export const applyWorkspaceProduct = (workspace: Workspace) => {
  if (featureIsEnabled(FLAG_INC_AI_WORKSPACE_LANGUAGE)) {
    if (isAIProductWorkspace(workspace)) {
      i18n.global.locale = 'en-x-ai'
    } else {
      i18n.global.locale = 'en'
    }
  }
}

export const isAIProductWorkspace = (workspace: Workspace) => {
  return workspace.product === 'ai'
}
