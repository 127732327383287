<template>
  <div class="callback-error-page">
    <div class="p-helper-abs-center">
      <div>
        <p-image :src="logo" :width="60" :height="60" class="logo" />

        <div class="title">{{ $t('title') }}</div>

        <div class="description">
          <DSMessageBox
            v-if="errorMessageFromQuery"
            class="message-box"
            data-testid="auth0-error"
          >
            {{ errorMessageFromQuery }}
          </DSMessageBox>

          <!-- eslint-disable vue/no-v-html -->
          <p v-html="$t('description_researcher')" />
          <!-- eslint-enable vue/no-v-html -->

          <!-- eslint-disable vue/no-v-html -->
          <p v-html="$t('description_participant')" />
          <!-- eslint-enable vue/no-v-html -->

          <!-- eslint-disable vue/no-v-html -->
          <p v-html="$t('description_ticket')" />
          <!-- eslint-enable vue/no-v-html -->
        </div>
        <div class="buttons">
          <DSButton size="large" @click="home">
            {{ $t('sign_in') }}
          </DSButton>
        </div>
      </div>
    </div>
  </div>
</template>

<i18n lang="yaml">
en:
  title: There was a problem signing in
  registration_title: There was a problem signing up
  description_researcher: If you are trying to login as a Researcher, please refer to our Help Centre article <a href="https://researcher-help.prolific.com/en/article/ae91e1" target="_blank">here</a>.
  description_participant: If you were trying to login as a Participant, you can find the relevant Help Centre article <a href="https://participant-help.prolific.com/en/article/8b5ee3" target="_blank">here</a>.
  description_ticket: If you're still experiencing issues, you can submit a support request from inside our Help Centre.
  sign_in: Try again
</i18n>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
import { DSButton, DSMessageBox } from '@prolific-oss/design-system'
import logo from '@/assets/svg/logo.svg'
import Image from '@/components/common/Image.vue'
import {
  URL_REUSE,
  GOOGLE_SIGN_UP_ATTEMPT,
  REFRESHED,
  RATE_LIMIT,
} from '@/views/auth/constants'

export default {
  name: 'PError',

  components: {
    DSButton,
    DSMessageBox,
    'p-image': Image,
  },

  data() {
    return {
      logo,
    }
  },

  computed: {
    errorMessageFromQuery() {
      const error = this.$route.query?.error_description
      if (!error) return null

      const ERROR_MAPPINGS = {
        [URL_REUSE.RECIEVED]: URL_REUSE.REFORMATTED,
        [REFRESHED.RECIEVED]: REFRESHED.REFORMATTED,
        [GOOGLE_SIGN_UP_ATTEMPT.RECIEVED]: GOOGLE_SIGN_UP_ATTEMPT.REFORMATTED,
        [RATE_LIMIT.RECIEVED]: RATE_LIMIT.REFORMATTED,
      }

      for (const [receivedError, reformattedMessage] of Object.entries(
        ERROR_MAPPINGS
      )) {
        if (error.includes(receivedError)) {
          return reformattedMessage
        }
      }

      return null
    },
  },

  methods: {
    home() {
      // not using $router in case issue is with that
      window.location.pathname = '/'
    },
  },
}
</script>

<style lang="scss" scoped>
.p-helper-abs-center {
  > div {
    background: $background-color-base;
    border-radius: $border-radius-8;
    padding: 60px;
  }
}

.select {
  display: inline-block !important;
  min-width: 320px;
  margin-bottom: $space-4;
}

.callback-error-page {
  background: $background-color-softer;
  color: $text-color-base;
  flex: 1;
  position: absolute;
  inset: 0;
  overflow: hidden;
  text-align: center;

  .title {
    font-size: $font-size-4;
    color: $color-primary-400;
    font-weight: $font-weight-semibold;
    margin-bottom: $space-4;
  }

  .description {
    font-size: $font-size-7;
    font-weight: $font-weight-normal;
    margin-bottom: $space-6;
    margin-left: auto;
    margin-right: auto;
    max-width: 780px;

    &.completion {
      font-size: $font-size-base;
      font-weight: $font-weight-semibold;
      background: $background-color-alarm-softest;
      border: $border-width-base solid $border-color-alarm;
      padding: $space-5;
      border-radius: $border-radius-4;
      margin-bottom: 0;
    }

    p {
      margin-bottom: $space-4;
    }

    :deep() {
      a,
      a:link,
      a:active,
      a:visited {
        border-bottom: $border-width-base dotted $border-color-primary;
        pointer-events: all;
        font-weight: $font-weight-semibold;
      }

      a:hover {
        color: $text-color-primary;
      }

      .cc {
        color: black;
        font-weight: $font-weight-bold;
      }
    }
  }

  .buttons {
    pointer-events: all;
  }

  .logo {
    margin-bottom: $space-4;
  }

  .message-box {
    margin-bottom: $space-4;
    justify-content: center;
  }
}
</style>
