/* eslint no-console: "warn" */
import { getKlaroConsentAsync } from '../klaro/getKlaroConsentAsync'
import { createQueue } from './createQueue'
import { debug } from './debug'
import { getGlobal } from './getGlobal'

/**
 * Creates a proxied object that sends calls to the real integration only
 * once the integration is available in global scope & if klaro consent was given.
 *
 * This is useful since we can then make integration/tracking calls throughout
 * the codebase without having to worry about these things everywhere.
 * For example, in a module somewhere we can do
 * ```
 * import snowplow from '@/integrations/snowplow'
 * snowplow('trackStructEvent',...) // proxied
 *```
 * The real snowplow lib will only be called if it exists and if the user has
 * given klaro consent. It works async so if calls to the proxy are made in
 * advance of klaro or the integration being loaded, then promises will be flushed
 * as soon as available.
 *
 */
export function createProxy<T extends object>({
  name,
  klaroConsentName = name,
  isDisabled = false,
  readyCallbackName,
}: {
  name: string
  klaroConsentName?: string
  isDisabled?: boolean
  readyCallbackName?: string
}) {
  const queue = createQueue()
  let pending = true
  let hasConsent: boolean
  let integration: T

  const checkCallable = async () => {
    hasConsent = await getKlaroConsentAsync(klaroConsentName)
    if (!hasConsent) {
      pending = false
      queue.flush()
      return
    }
    try {
      integration = await getGlobal(name, readyCallbackName)
      queue.flush()
    } catch {
      queue.flush()
    }
    pending = false
  }

  checkCallable()

  const getConsent = () => hasConsent
  const getIntegration = () => integration

  const handleCall = (args: unknown[], prop?: string | symbol) => {
    if (isDisabled) {
      debug('❌ integration disabled', { name, prop, args })
      return
    }

    if (!getConsent()) {
      debug('❌ no consent given', { name, prop, args })
      return
    }

    const integration = getIntegration()
    if (!integration) {
      debug(`❌ window.${name} not found`, { name, prop, args })
      return
    }

    try {
      if (prop) {
        // the integration is an object like `pendo.track(...)`
        ;(integration[prop as keyof T] as (...args: unknown[]) => void)(...args)
      } else {
        // this integration is a function like `snowplow(...)`
        ;(integration as (...args: unknown[]) => void)(...args)
      }
      debug('✔ integration called', { name, prop, args })
    } catch (error) {
      debug('❌ proxy call failed', { name, prop, args })
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const queueOrCall = (args: unknown[], prop?: string | symbol) => {
    const call = () => handleCall(args, prop)
    if (pending) {
      queue.push(call)
      return
    }
    call()
  }

  return new Proxy(
    ((...args: unknown[]) => {
      queueOrCall(args)
    }) as T,
    {
      get(target, prop) {
        if (targetHasProperty(target, prop)) {
          return target[prop]
        }
        return (...args: unknown[]) => {
          queueOrCall(args, prop)
        }
      },

      set(target, prop, value) {
        Object.assign(target, { [prop]: value })
        return true
      },
    }
  )
}

function targetHasProperty<T extends object>(
  target: T,
  property: string | symbol | number
): property is keyof T {
  return property in target
}
