import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import 'firebase/compat/firestore'

// Use firestore
const db = firebase
  .initializeApp({
    projectId: import.meta.env.VUE_APP_FIREBASE_PROJECT_ID,
    apiKey: import.meta.env.VUE_APP_FIREBASE_API_KEY,
  })
  .firestore()

// Configures the SDK's underlying transport (WebChannel) to use long polling.
// This avoids incompatibility issues with certain proxies, antivirus software, etc. that incorrectly buffer traffic indefinitely.
// https://firebase.google.com/docs/reference/js/firebase.firestore.Settings#experimentalforcelongpolling
// Possible solution for BKLG-245
db.settings({ experimentalForceLongPolling: true })

// if running against local emulator
if (import.meta.env.VUE_APP_FIREBASE_MODE === 'emulator') {
  const disableWarnings =
    import.meta.env.VUE_APP_FIREBASE_AUTH_WARNING === 'off'

  db.useEmulator('localhost', 5000)
  firebase.auth().useEmulator('http://localhost:9099', { disableWarnings })
}
const { firestore } = firebase
const { Timestamp, FieldPath, DocumentReference } = firestore
const { serverTimestamp, increment } = firestore.FieldValue

export default firebase

export {
  firebase,
  increment,
  db,
  firestore,
  Timestamp,
  FieldPath,
  serverTimestamp,
  DocumentReference,
}
