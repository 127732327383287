import { trackError } from '@/analytics/common'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_IB_PEC_ERRORS_MODAL } from '@/integrations/launchDarkly/active-flags'
import rollbar from '@/integrations/rollbar'
import router from '@/router'
import store from '@/store'
import { snakeCaseObject } from '@/utils'
import notifier from '@/utils/notifier'

const MAX_RETRIES = 3

let connectionWarning
function handleOffline() {
  if (!connectionWarning) {
    window.addEventListener('online', handleReconnect)
    connectionWarning = notifier.notification({
      showClose: false,
      duration: 0,
      type: 'warning',
      title: 'No internet. Trying to reconnect...',
      customClass: 'no-body',
    })
  }
}

function handleReconnect() {
  connectionWarning.close()
  connectionWarning = null
  window.removeEventListener('online', handleReconnect)
}

export function handleInteractiveError(detail) {
  // PEC-SUB-0002 and PEC-AB-0004 are errors that are displayed in a modal and should not be displayed in the notification bar
  if (
    featureIsEnabled(FLAG_IB_PEC_ERRORS_MODAL) &&
    ['PEC-SUB-0002', 'PEC-AB-0004'].includes(detail)
  ) {
    // Trigger the error modal
    return store.dispatch('participant/submissions/showPECErrorModal', true)
  } else {
    return notifier.error(detail, {
      duration: 0,
      dangerouslyUseHTMLString: true,
    })
  }
}

function handle500Error(error) {
  const detail = error?.detail
  const errorCode = error?.error_code
  if (store.state.global.loading) {
    trackError(500, '500 experienced during route transition', errorCode)

    // do full error page if transitioning routes
    return router.replace({ name: 'error.500' })
  }
  // otherwise display a more subtle error
  notifier.error(
    "Sorry, we're experiencing some issues. Please try again. If the problem persists, please contact support."
  )

  trackError(500, detail ?? 'Something went wrong', errorCode)
}

function handle429Error() {
  if (store.state.global.loading) {
    // do full error page if transitioning routes
    return router.replace({ name: 'error.429' })
  }
  // otherwise display a more subtle error
  notifier.error('Too many requests. Please try again later.')
}

function handle404Error(error) {
  const detail = error?.detail
  const errorCode = error?.error_code
  if (store.state.global.loading) {
    trackError(404, '404 experienced during route transition', errorCode)

    // do full error page if transitioning routes
    return router.replace({ name: 'error.404' })
  }
  // otherwise display a more subtle error
  notifier.error(
    'Sorry, we could not perform that action. Please refresh your browser and try again. If the problem persists, please contact support.'
  )

  trackError(404, detail ?? 'Something went wrong', errorCode)
}

function handle403Error(error) {
  const errorCode = error?.error_code
  if (store.state.global.loading) {
    trackError(404, '404 experienced during route transition', errorCode)

    // Treat 403 like 404 if transitioning routes
    return router.replace({ name: 'error.404' })
  }

  // otherwise treat like other 4XX errors
  return handle4XXError(error)
}

function handle4XXError(error) {
  const { detail, interactive, status, error_code } = error
  // the api can return errors in various formats
  if (typeof detail === 'string') {
    // string
    if (interactive) {
      handleInteractiveError(detail)
    } else {
      notifier.error(detail)
    }
    trackError(status, detail, error_code)
  } else if (Array.isArray(detail) && typeof detail[0] === 'string') {
    // an array of strings
    if (interactive) {
      handleInteractiveError(detail)
    } else {
      notifier.error(detail[0].trim())
    }

    trackError(status, detail[0].trim(), error_code)
  } else if (typeof detail === 'object' && !Array.isArray(detail)) {
    // an object
    if (Object.prototype.hasOwnProperty.call(detail, 'non_field_errors')) {
      // an object with non_field_errors property
      let { non_field_errors: nonFieldErrors } = detail
      if (Array.isArray(nonFieldErrors)) {
        nonFieldErrors = nonFieldErrors[0]
      }
      if (interactive) {
        handleInteractiveError(nonFieldErrors)
      } else {
        notifier.error(nonFieldErrors)
      }

      trackError(status, nonFieldErrors, error_code)
    } else {
      trackError(status, 'Something went wrong', error_code)
    }
  }
}

/**
 * Add http interceptors to axiosInstance
 * @param {axiosInstance} http
 */
export default function (http, useSnakeCase = true) {
  const retry = (milliseconds, errorObj) => {
    const { config: originalRequest } = errorObj
    originalRequest.params = originalRequest.params || {}
    originalRequest.params['_RETRY_COUNT_'] =
      (Number.parseInt(originalRequest.params['_RETRY_COUNT_']) || 0) + 1
    if (originalRequest.data) {
      originalRequest.data = JSON.parse(originalRequest.data)
    }
    return new Promise((resolve, reject) => {
      const retryAfterDelay = () =>
        setTimeout(() => resolve(http.request(originalRequest)), milliseconds)
      if (originalRequest.params['_RETRY_COUNT_'] <= MAX_RETRIES) {
        retryAfterDelay()
      } else {
        if (!navigator.onLine) {
          retryAfterDelay()
          handleOffline()
        } else {
          handle500Error()
          rollbar.error(
            'Empty response in interceptor after 3 retries. Internet enabled.'
          )
          reject(new Error('Server error'))
        }
      }
    })
  }

  http.interceptors.request.use(
    config => {
      if (config.data !== undefined && useSnakeCase) {
        config.data = snakeCaseObject(config.data)
      }
      // Intercept every request and put the current workspace id on x-workspace-id header (if it exists)
      // We do this to improve our logging/monitoring on a per workspace basis
      if (store.state?.researcher?.workspaces?.workspace?.id) {
        config.headers['x-workspace-id'] =
          store.state.researcher.workspaces.workspace.id
      }
      // Send current client version along with all requests so that we know the client version when
      // viewing error logs on datadog
      config.headers['x-client-version'] =
        import.meta.env['VUE_APP_GIT_HEAD_SHA'] ?? 'unknown'

      return config
    },
    _error => {}
  )

  http.interceptors.response.use(
    response => response.data,
    /**
     * This is a central point to handle all
     * errors generated by HTTP
     * requests
     */
    errorObj => {
      const { response, message } = errorObj
      const useGlobalErrorHandling = errorObj?.config?.useGlobalErrorHandling
      const useGlobal429ErrorHandling =
        errorObj?.config?.useGlobal429ErrorHandling

      if (useGlobalErrorHandling === false) {
        return Promise.reject(errorObj)
      }

      if (message === 'canceled') {
        return Promise.reject(errorObj)
      }

      if (response === undefined) {
        return retry(1000, errorObj)
      }

      const { data, status } = response
      errorObj.status = status

      let { statusText } = response
      if (!statusText) {
        statusText = 'Bad request'
      }

      const { error } = data

      if (status === 500) {
        handle500Error(error)
        return Promise.reject(errorObj)
      }

      if (status === 429) {
        if (useGlobal429ErrorHandling !== false) {
          handle429Error()
        }
        return Promise.reject(errorObj)
      }

      if (status === 404) {
        handle404Error(error)
        return Promise.reject(errorObj)
      }

      if (status === 403) {
        handle403Error(error)
        return Promise.reject(errorObj)
      }

      if (error === undefined) {
        notifier.error(errorObj.message)
        trackError(status, errorObj.message)
        return Promise.reject(errorObj)
      }

      error.status = status
      handle4XXError(error)
      return Promise.reject(error)
    }
  )
}
