import store from '@/store'

const Messages = () => import('@/views/messages/Messages.vue')

// don't allow banned users to access
// messaging due to abuse
const beforeEnter = async (to, from, next) => {
  if (store.getters['auth/isBanned']) {
    return next({ name: 'home' })
  }
  try {
    await store.state.messages.loginPromise
  } catch (_error) {
    // firebase login failed
    return next({ name: 'error.500' })
  }
  next()
}

export default [
  {
    path: 'messages',
    redirect: { name: 'messages.inbox' },
  },
  {
    path: 'messages/inbox',
    name: 'messages.inbox',
    component: Messages,
    beforeEnter,
  },
  {
    path: 'messages/archive',
    name: 'messages.archive',
    component: Messages,
    beforeEnter,
  },
  {
    path: 'messages/:channel',
    name: 'messages.channel',
    component: Messages,
    beforeEnter,
  },
]
