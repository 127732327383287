/* eslint no-console: "warn" */

/* eslint-disable no-restricted-imports */
import { FormTrackingPlugin } from '@snowplow/browser-plugin-form-tracking'
import { PerformanceNavigationTimingPlugin } from '@snowplow/browser-plugin-performance-navigation-timing'
import {
  SiteTrackingPlugin,
  trackSiteSearch as spTrackSiteSearch,
} from '@snowplow/browser-plugin-site-tracking'
import * as sp from '@snowplow/browser-tracker'
import once from 'lodash/once'
import type { Participant, Researcher } from '@/api/types'
import { createQueue } from './helpers/createQueue'
import { debug } from './helpers/debug'
import { getKlaroConsentAsync } from './klaro/getKlaroConsentAsync'

// NOTE(BJ): This is probably stupid, but we can't directly proxy the browser tracker
// because it is non-configurable/non-writable which means you can't replace
// methods on it. Proxying the browser tracker also would require a separate
// proxy for plugins such as trackSiteSearch.
// WARNING: This proxy issue will only reveal itself in production builds.
const snowplowApi = {
  trackSiteSearch: spTrackSiteSearch,
  addGlobalContexts: sp.addGlobalContexts,
  clearGlobalContexts: sp.clearGlobalContexts,
  setUserId: sp.setUserId,
  trackSelfDescribingEvent: sp.trackSelfDescribingEvent,
  trackStructEvent: sp.trackStructEvent,
  newTracker: sp.newTracker,
  trackPageView: sp.trackPageView,
}

// TODO(BJ): Obviously this is heavily inspired by ./helpers/createProxy
// but it doesn't rely on the module being available on window.
// If this fixes the snowplow duplicate sessions, we'll look to try and
// merge the functionality, or at least make this reusable. Ticket: SSG-308.
function createProxy() {
  const name = 'snowplow'
  const queue = createQueue()
  let pending = true
  let hasConsent: boolean

  const checkCallable = async () => {
    hasConsent = await getKlaroConsentAsync(name)

    sp.newTracker('co', import.meta.env['VUE_APP_SNOWPLOW_COLLECTOR_URL'], {
      appId: import.meta.env['VUE_APP_SNOWPLOW_APP_ID'],
      discoverRootDomain: true,
      eventMethod: 'post',
      ...(hasConsent
        ? { stateStorageStrategy: 'cookieAndLocalStorage' }
        : {
            anonymousTracking: { withServerAnonymisation: true },
            stateStorageStrategy: 'none',
          }),
      contexts: {
        webPage: true,
      },
      plugins: [
        SiteTrackingPlugin(),
        PerformanceNavigationTimingPlugin(),
        FormTrackingPlugin(),
      ],
    })

    queue.flush()
    pending = false
  }

  checkCallable()

  const handleCall = (args: unknown[], prop: string | symbol) => {
    try {
      ;(
        snowplowApi[prop as keyof typeof snowplowApi] as (
          ...args: unknown[]
        ) => void
      )(...args)
      debug('✔ integration called', { name, prop, args })
    } catch (error) {
      debug('❌ proxy call failed', { name, prop, args })
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  const queueOrCall = (args: unknown[], prop: string | symbol) => {
    const call = () => handleCall(args, prop)
    if (pending) {
      debug('❌ integration not ready, queueing call', { name, prop, args })
      queue.push(call)
      return
    }
    call()
  }

  return new Proxy(snowplowApi, {
    get(_target, prop: keyof typeof snowplowApi) {
      return (...args: unknown[]) => {
        queueOrCall(args, prop)
      }
    },
  })
}

export const snowplow = createProxy()

export const createSchema = (name: string, version = '1-0-0') =>
  `iglu:co.prolific/${name}/jsonschema/${version}`

function isResearcher(user: Participant | Researcher): user is Researcher {
  return user.user_type === 'researcher'
}

function isParticipant(user: Participant | Researcher): user is Participant {
  return user.user_type === 'participant'
}

// TODO: take from api
type Workspace = {
  id: string
  experimental_group: number
}

type User = (Participant & { pa_enabled?: boolean }) | Researcher

const createUserContext = (getUser: () => User) => () => {
  const user = getUser()
  const { id, user_type, status, balance, currency_code } = user
  const data = {
    id,
    user_type,
    status,
    balance,
    currency_code,
    role_in_event: 'actor',
    ...(isResearcher(user)
      ? {
          available_balance: user.available_balance,
          last_login: user.last_login,
        }
      : {}),
    ...(isParticipant(user) ? { pa_enabled: user.pa_enabled } : {}),
  }

  return {
    schema: createSchema('user', '2-0-0'),
    data,
  }
}

const createWorkspaceContext = (getWorkspace: () => Workspace) => () => {
  const { id, experimental_group } = getWorkspace()
  return {
    schema: createSchema('workspace', '1-0-2'),
    data: {
      id,
      experimental_group,
    },
  }
}

export const setUserContext = once((getUser: () => User) => {
  // first set user id
  snowplow.setUserId(getUser().id)
  // now send user context with all events
  snowplow.addGlobalContexts([createUserContext(getUser)])
})

export const setWorkspaceContext = once((getWorkspace: () => Workspace) => {
  snowplow.addGlobalContexts([createWorkspaceContext(getWorkspace)])
})

export const trackCustomEvent = ({
  name,
  data,
  version = '1-0-0',
}: {
  name: string
  data: Record<string, unknown>
  version: string
}) => {
  snowplow.trackSelfDescribingEvent({
    event: {
      schema: createSchema(name, version),
      data,
    },
  })
}

export const trackStructEvent = ({
  category,
  action,
  label,
  property,
  value,
  context,
}: {
  category: string
  action: string
  label?: string
  property?: string
  value?: number
  context?: sp.SelfDescribingJson<Record<string, unknown>>[]
}) => {
  snowplow.trackStructEvent({
    category,
    action,
    label,
    property,
    value,
    context,
  })
}

export const trackSiteSearch = ({
  terms,
  filters,
  totalResults,
  pageResults,
}: {
  terms: string[]
  filters?: Record<string, string | boolean>
  totalResults?: number
  pageResults?: number
}) => {
  spTrackSiteSearch({
    terms,
    filters,
    totalResults,
    pageResults,
  })
}

export const teardown = () => {
  snowplow.clearGlobalContexts()
}

export const trackOnboardingFlow = (
  action: string,
  { isUrlCampaign = false }
) => {
  const category = isUrlCampaign
    ? 'fasttracking_url_campaign_v1'
    : 'waitlist_invites_v1'

  snowplow.trackStructEvent({
    category,
    action,
  })
}

export { enableFormTracking } from '@snowplow/browser-plugin-form-tracking'
export type { StructuredEvent } from '@snowplow/browser-tracker'
