import axios from 'axios'
import type { Module } from 'vuex'
import { http, endpoints } from '@/api'
import type {
  BatchInstruction,
  BatchTaskInGroup,
  BatchTask,
  TaskGroup,
  TaskGroupResponse,
  TaskResponse,
  UnitOfDataSection,
  TaskDetails,
} from '@/api/types'

export type BatchTaskWithUnitOfData = BatchTaskInGroup & {
  unitOfData?: UnitOfDataSection[]
}

export type State = {
  instructions: BatchInstruction[] | null
  tasks: BatchTaskWithUnitOfData[] | null
  loadingUnitOfData: boolean
  submittingTaskResponse: boolean
  taskDetails: TaskDetails
}

const SET_INSTRUCTIONS = 'SET_INSTRUCTIONS'
const SET_TASKS = 'SET_TASKS'
const SET_LOADING_UNIT_OF_DATA = 'SET_LOADING_UNIT_OF_DATA'
const SET_TASK_UNIT_OF_DATA = 'SET_TASK_UNIT_OF_DATA'
const SET_SUBMITTING_TASK_RESPONSE = 'SET_SUBMITTING_TASK_RESPONSE'
const SET_TASK_DETAILS = 'SET_TASK_DETAILS'

export default {
  namespaced: true,

  state: {
    instructions: null,
    tasks: null,
    loadingUnitOfData: false,
    submittingTaskResponse: false,
    taskDetails: {},
  } as State,

  mutations: {
    [SET_INSTRUCTIONS](state: State, instructions: BatchInstruction[]) {
      state.instructions = instructions
    },

    [SET_TASKS](state: State, tasks: BatchTaskWithUnitOfData[]) {
      state.tasks = tasks
    },

    [SET_LOADING_UNIT_OF_DATA](state: State, loadingUnitOfData: boolean) {
      state.loadingUnitOfData = loadingUnitOfData
    },

    [SET_TASK_UNIT_OF_DATA](
      state: State,
      {
        taskIndex,
        unitOfData,
      }: { taskIndex: number; unitOfData: UnitOfDataSection[] }
    ) {
      const task = state.tasks?.[taskIndex]
      if (task) task.unitOfData = unitOfData
    },

    [SET_SUBMITTING_TASK_RESPONSE](
      state: State,
      submittingTaskResponse: boolean
    ) {
      state.submittingTaskResponse = submittingTaskResponse
    },

    [SET_TASK_DETAILS](state: State, taskDetails: TaskDetails) {
      state.taskDetails = taskDetails
    },
  },

  actions: {
    async fetchTask({ commit, dispatch }, { batchId, taskId }) {
      const task = await http.get<BatchTask>(
        endpoints.DCTOOL_BATCHES_TASK(batchId, taskId)
      )
      commit(SET_TASKS, [task])
      dispatch('fetchUnitOfData')
    },

    async fetchTaskGroup({ commit, dispatch }, { batchId, taskGroupId }) {
      const taskGroup = await http.get<TaskGroup>(
        endpoints.DCTOOL_BATCHES_TASK_GROUP(batchId, taskGroupId)
      )
      commit(SET_INSTRUCTIONS, taskGroup.instructions)
      commit(SET_TASKS, taskGroup.tasks)
      commit(SET_TASK_DETAILS, taskGroup.task_details)

      // fetch the first unit of data
      dispatch('fetchUnitOfData')
    },

    setLoadingUnitOfData({ commit }, loading: boolean) {
      commit(SET_LOADING_UNIT_OF_DATA, loading)
    },

    async fetchUnitOfData({ commit, state, dispatch }, taskIndex = 0) {
      const task = state.tasks?.[taskIndex]
      if (task) {
        dispatch('setLoadingUnitOfData', true)
        const unitOfDataUrl = task.data_points[0]?.reference.asset_url
        if (unitOfDataUrl) {
          const { data: unitOfData } = await axios.get(unitOfDataUrl)
          commit(SET_TASK_UNIT_OF_DATA, { taskIndex, unitOfData })
        }
        dispatch('setLoadingUnitOfData', false)
      }
    },

    setSubmittingTaskResponse({ commit }, submitting: boolean) {
      commit(SET_SUBMITTING_TASK_RESPONSE, submitting)
    },

    async createTaskResponse(
      { dispatch, rootGetters },
      {
        batchId,
        taskId,
        responses,
      }: {
        batchId: string
        taskId: string
        responses: TaskResponse[]
      }
    ) {
      dispatch('setSubmittingTaskResponse', true)
      await http.post(endpoints.DCTOOL_BATCHES_TASK(batchId, taskId), {
        participant_id: rootGetters['auth/user'].id,
        responses,
      })
    },

    async createTaskGroupResponse(
      { dispatch, rootGetters },
      {
        batchId,
        taskGroupId,
        responses,
      }: {
        batchId: string
        taskGroupId: string
        responses: TaskGroupResponse[]
      }
    ) {
      dispatch('setSubmittingTaskResponse', true)
      await http.post(
        endpoints.DCTOOL_BATCHES_TASK_GROUP(batchId, taskGroupId),
        {
          participant_id: rootGetters['auth/user'].id,
          submission_id: rootGetters['participant/submissions/submission'].id,
          correlation_id: rootGetters['participant/studies/denormalized'].id,
          responses,
        }
      )
    },

    reset({ commit, dispatch }) {
      commit(SET_INSTRUCTIONS, null)
      commit(SET_TASKS, null)
      dispatch('setSubmittingTaskResponse', false)
    },
  },

  getters: {
    instructions: ({ instructions }) => instructions,
    tasks: ({ tasks }) => tasks,
    loadingUnitOfData: ({ loadingUnitOfData }) => loadingUnitOfData,
    submittingTaskResponse: ({ submittingTaskResponse }) =>
      submittingTaskResponse,
    taskDetails: ({ taskDetails }) => taskDetails,
  },
} satisfies Module<State, unknown>
