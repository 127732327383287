import isoCountries from 'i18n-iso-countries/langs/en.json'
import isEmpty from 'lodash/isEmpty'
import startCase from 'lodash/startCase'
import { parse } from 'papaparse'
import isEmail from 'validator/lib/isEmail'
import isUrl from 'validator/lib/isURL'
import { arrayToPairs, csvToArray } from '@/utils'

const countryCodes = Object.keys(isoCountries.countries)

// TODO: VAT countries don't always follow ISO-3166
// For example, Greece = EL, not GR. We will need to
// investigate further but this is a temp fix
countryCodes.push('EL')

const isNumber = val => /^\d+$/.test(val || '')

const hasNoNumber = str => !str.match(/\d+/g)?.length

const isAmount = val => /^[0-9]+(\.[0-9]{1,2})?$/.test(val || '')

const isPhoneNumber = val => /^[\d()+\s-]{8,}$/.test(val || '')

const isPostalCode = val =>
  /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/i.test(val || '')

export const isMongoId = val => /^[a-f\d]{24}$/i.test(val || '')

// TODO: migrate to TS
/** @type {(fn: (val: unknown, name: string, opts?: Record<string, string | number>) => [boolean, string, string]) => (opts?: Record<string, string | number>) => (name: string) => (value: unknown, allValues: Record<string, unknown>) => string | undefined} */
export function createValidator(fn) {
  return ({ msg: customMsg, ...opts } = {}) =>
    name =>
    (value, allValues) => {
      const [test, msg, msgWithName] = fn(
        value,
        name,
        opts,
        allValues,
        customMsg
      )
      return test ? '' : customMsg || (name ? msgWithName : msg)
    }
}

export function chainValidators(...validators) {
  return opts =>
    name =>
    (...values) => {
      for (const validator of validators) {
        const error = validator(opts)(name)(...values)
        if (error) {
          return error
        }
      }
      // no error
      return ''
    }
}

export const custom = (fn, message) => () => (value, allValues) => {
  return fn(value, allValues) ? '' : message || true
}

export const required = createValidator((val, name) => [
  (typeof val === 'object' && !(val instanceof Date)) || typeof val === 'string'
    ? !isEmpty(val)
    : val !== undefined && val !== false,
  'This field is required',
  `${name} is required`,
])

export const isValidName = createValidator((val, name) => [
  hasNoNumber(val || ''),
  'Please enter a valid name',
  `${name} must be valid`,
])

export const email = createValidator((val, name) => [
  isEmail(val || ''),
  'Please enter a valid email address',
  `${name} must be valid`,
])

export const emailList = createValidator((val, name) => [
  val && csvToArray(val).every(email => isEmail(email)),
  'Please enter a valid email address',
  `${name} must be a comma-separated list of email addresses`,
])

export const maxListLength = createValidator((val, name, { length }) => [
  val && csvToArray(val).length <= length,
  `Please enter fewer than ${length} items`,
  `${name} must not exceed ${length}`,
])

export const phoneNumber = createValidator((val, name) => [
  isPhoneNumber(val),
  'Please enter a valid phone number',
  `${name} must be valid`,
])

export const postalCode = createValidator((val, name) => [
  isPostalCode(val) || !val,
  'Please enter a valid postal code',
  `${name} must be valid`,
])

export const number = createValidator((val, name) => [
  isNumber(val),
  'Please enter a number',
  `${name} must be a number`,
])

export const matches = createValidator((val, name, { other }, allValues) => [
  allValues && val === allValues[other],
  `Must match ${startCase(other)}`,
  `${name} must match ${startCase(other)}`,
])

export const minLength = createValidator((val, name, { length = 6 }) => [
  val && val.length >= length,
  `Must be at least ${length} characters`,
  `${name} must be at least ${length} characters`,
])

export const maxLength = createValidator((val, name, { length = 10 }) => [
  !val || val.length <= length,
  `Must be at most ${length} characters`,
  `${name} must be at most ${length} characters`,
])

export const url = createValidator((val, name) => [
  isUrl(val || ''),
  'Please enter a valid url',
  `${name} must be a valid url`,
])

export const protocol = createValidator((val, name) => [
  val.match(/^https?:\/\//),
  'Please use a link starting with http:// or https://',
  `${name} must start with http:// or https://`,
])

export const minNumber = chainValidators(
  number,
  createValidator((val, name, { min = 1 }) => [
    val && Number(val) >= min,
    `Must be at least ${min}`,
    `${name} must be at least ${min}`,
  ])
)

export const maxNumber = chainValidators(
  number,
  createValidator((val, name, { max = Infinity }) => [
    val && Number(val) <= max,
    `Must be at most ${max}`,
    `${name} must be at most ${max}`,
  ])
)

export const unlimitedOrLimitedNumber = createValidator((val, name) => [
  !Number.isNaN(Number(val)) && (Number(val) > 0 || Number(val) === -1),
  'Please enter a valid limit',
  `${name} should be a number greater than 0, or alternatively, -1.`,
])

export const between = chainValidators(
  number,
  createValidator((val, name, { min = 1, max = Infinity }) => [
    val && val >= min && val <= max,
    `Must be between ${min} and ${max}`,
    `${name} must be between ${min} and ${max}`,
  ])
)

export const mongoIds = createValidator((val, name) => [
  csvToArray(val)?.every(id => isMongoId(id)),
  `The highlighted Prolific IDs aren't valid. Correct or remove them to continue.`,
  `${name} must be a valid list of ids`,
])

export const bonusPayments = createValidator((val, name) => [
  val &&
    arrayToPairs(csvToArray(val)).every(
      ([id, amount]) => isMongoId(id) && isAmount(amount)
    ),
  'Invalid bonus payment format',
  `${name} must be in valid bonus payment format`,
])

const vatRegExp = new RegExp(`^(${countryCodes.join('|')})[a-zA-Z0-9]+`)

export const vatNumber = createValidator((val, name) => [
  (val && val.match(vatRegExp)) || val === '',
  'Please enter a valid VAT number, including the country prefix',
  `${name} must be a valid VAT number`,
])

function isDateOverXYearsOld(date, numYears) {
  if (date instanceof Date) {
    const year = date.getFullYear()
    const month = date.getMonth()
    const day = date.getDate()

    return new Date(year + numYears, month, day) <= new Date()
  }

  // If the date is not a Date object, we assume it's a string in the UTC format
  // (YYYY-MM-DDT00:00:00.000Z) and we convert it to a Date object
  const [year, month, day] = date.split('T')[0].split('-')
  return isDateOverXYearsOld(new Date(year, month - 1, day), numYears)
}

export const dateIs18 = createValidator((val, name) => [
  val && isDateOverXYearsOld(val, 18),
  'Must be at least 18 years old',
  `${name} must be at least 18 years old`,
])

export const dateIsOver105 = createValidator((val, name) => [
  val && !isDateOverXYearsOld(val, 105),
  'Please enter a valid date',
  `${name} must be a valid date`,
])

export const quotaBalance = createValidator((val, name, { max }) => [
  max >= Number(val),
  // TODO(ai-mode): translate properly
  `Not enough matching participants. We can only offer a maximum of ${max} to balance your study. Please change the amount of participants to recruit.`,
  `${name} must not exceed ${max}`,
])

export const duplicateIds = createValidator((val, name) => {
  const ids = csvToArray(val)
  return [
    new Set(ids)?.size === ids?.length,
    'The highlighted Prolific IDs are duplicated. Remove any duplicates to continue.',
    `${name} must be a valid list of unique ids`,
  ]
})

export const allowListIds = chainValidators(mongoIds, duplicateIds)

export const securePassword = createValidator((val, name) => {
  const forbidden = [
    'password',
    'prolific',
    'research',
    'researcher',
    'participant',
    'qwerty',
    'azerty',
    '123456',
    '987654',
  ]
  const password = val.toLowerCase()

  return [
    !forbidden.some(value => {
      return password.includes(value)
    }),
    `Password contains guessable words`,
    `${name} must not be too simple.`,
  ]
})

/**
 *
 * @param {string} value - The value of the input.
 * @param {number} numberOfColumns - Optional configuration property that enforces the number of columns in the input value.
 * @returns {boolean}
 */
const isValidCSV = (value, numberOfColumns) => {
  const results = parse(value, {
    delimiter: ',',
    header: true,
    skipEmptyLines: true,
  })

  if (results.errors.length) {
    return false
  }

  if (numberOfColumns) {
    return results.meta.fields.length === numberOfColumns
  }

  return true
}

export const csv = createValidator((val, name, { numberOfColumns }) => [
  isValidCSV(val, numberOfColumns),
  'Please provide valid CSV content',
  `${name} must be valid CSV`,
])

export const csvLength = csv => {
  if (!csv) return 0
  return csv
    .split('\n')
    .map(line => line.trim())
    .filter(Boolean).length
}

export const csvIsLongEnough = createValidator((val, name, { min }) => [
  csvLength(val) >= min,
  `Must provide at least ${min} credentials`,
  `${name} must be valid CSV`,
])

const forbiddenPasswords = {
  prolific: ['prolific', 'research', 'participant'],
  common: ['qwerty', 'azerty', '123456', '987654', 'password'],
  user: [],
}

export const minimumPasswordLength = 10

export const passwordChecks = [
  {
    key: 'min-length',
    test: password => new RegExp(`.{${minimumPasswordLength},}`).test(password),
    message: `Password must be at least ${minimumPasswordLength} characters`,
  },
  {
    key: 'not-numeric',
    test: password => !/^[0-9]*$/.test(password),
    message: 'Password must not only contain numbers',
  },
  {
    key: 'prolific-related',
    test: password => {
      return !forbiddenPasswords.prolific.some(value =>
        password.toLowerCase().includes(value)
      )
    },
    message: 'Password must not contain words related to Prolific',
  },
  {
    key: 'common',
    test: password => {
      return !forbiddenPasswords.common.some(value =>
        password.toLowerCase().includes(value)
      )
    },
    message: 'Password must not contain easily guessable words',
  },
  {
    key: 'personal',
    test: password => {
      return !forbiddenPasswords.user.some(value =>
        password.toLowerCase().includes(value.toLowerCase())
      )
    },

    message: 'Password must not contain your name or email address',
  },
]

export const isValidPassword = createValidator((val = '', name) => {
  return isValidPasswordLogic(val, name)
})

export const isValidPasswordLogic = (val, name, userData = []) => {
  const failedChecks = collectFailedPasswordChecks(val, name, userData)

  if (failedChecks.length > 0) {
    return [false, failedChecks[0].message, `${name} must be valid`]
  }

  return [true, '', `${name} must be valid`]
}

export const collectFailedPasswordChecks = (val, name, userData = []) => {
  if (userData.length > 0) {
    forbiddenPasswords.user = userData
  }

  return passwordChecks.filter(check => {
    const valid = check.test(val)
    return !valid
  })
}
