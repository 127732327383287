// FILTER IDS
export const PARTICIPANT_GROUP_ALLOWLIST_FILTER_ID =
  'participant_group_allowlist'
export const CURRENT_COUNTRY_OF_RESIDENCE_FILTER_ID =
  'current-country-of-residence'
export const COUNTRY_OF_RESIDENCE_FILTER_ID = 'country-of-residence'
export const COUNTRY_OF_BIRTH_FILTER_ID = 'country-of-birth'
export const NATIONAILITY_FILTER_ID = 'nationality'
export const SEX_FILTER_ID = 'sex'
export const AGE_FILTER_ID = 'age'
export const ETHNICITY_SIMPLIFIED_FILTER = 'ethnicity-simplified'
export const CUSTOM_BLOCKLIST_FILTER_ID = 'custom_blocklist'
export const PREVIOUS_STUDIES_FILTER_ID = 'previous_studies_blocklist'
export const FIRST_LANGUAGE_FILTER_ID = 'first-language'
export const AI_TASKERS_FILTER_ID = 'ai-taskers'
export const ANDROID_OS_VERSION_FILTER_ID = 'android-os-version'
export const APPROVAL_NUMBERS_FILTER_ID = 'approval_numbers'
export const APPROVAL_RATE_FILTER_ID = 'approval_rate'
export const RECORD_AUDIO_FILTER_ID = 'record-audio'
export const RECORD_VIDEO_FILTER_ID = 'record-video'
export const RECORD_SCREEN_FILTER_ID = 'record-screen'
export const WEBCAM_FILTER_ID = 'webcam'
export const OTHER_CROWDSOURCING_PLATFORMS_FILTER_ID =
  'other-crowdsourcing-platforms'
export const OTHER_CROWDSOURCING_PLATFORMS_SELECT_FILTER_ID =
  'other-crowdsourcing-platforms-select'
export const PARTICIPATING_TOGETHER_WITH_A_FRIEND_ON_PROLIFIC_FILTER_ID =
  'participating-together-with-a-friend-on-prolific'
export const PARTICIPATING_TOGETHER_WITH_A_ROMANTIC_PARTNER_ON_PROLIFIC_FILTER_ID =
  'participating-together-with-a-romantic-partner-on-prolific'
export const PHONE_OPERATING_SYSTEM_FILTER_ID = 'phone-operating-system'
export const COMPUTER_OPERATING_SYSTEM_FILTER_ID = 'computer-operating-system'
export const CONFIDENTIALITY_AGREEMENT_FILTER_ID = 'confidentiality-agreement'
export const DECEPTION_FILTER_ID = 'deception'
export const HARMFUL_CONTENT_FILTER_ID = 'harmful-content'
export const IPHONE_MODEL_FILTER_ID = 'iphone-model'
export const JOINED_BETWEEN_FILTER_ID = 'joined_between'
export const CUSTOM_ALLOWLIST_FILTER_ID = 'custom_allowlist'
export const US_POLITICAL_AFFILIATION_FILTER_ID = 'us-political-affiliation'
export const UK_POLITICAL_AFFILIATION_FILTER_ID = 'uk-political-affiliation'
export const FLUENT_LANGUAGES_FILTER_ID = 'fluent-languages'

export const POPULAR_FILTER_IDS = [
  CURRENT_COUNTRY_OF_RESIDENCE_FILTER_ID,
  FLUENT_LANGUAGES_FILTER_ID,
  AGE_FILTER_ID,
  SEX_FILTER_ID,
  PREVIOUS_STUDIES_FILTER_ID,
  APPROVAL_RATE_FILTER_ID,
  APPROVAL_NUMBERS_FILTER_ID,
]
