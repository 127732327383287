import { isEmpty } from 'lodash'
import merge from 'ts-deepmerge'
import { PARTICIPANT_STUDIES_CHANGE_SORT } from '@/analytics/events'
import {
  trackStudyDashboardEvent,
  trackStudyDashboardLoad,
  trackStudyDashboardSort,
} from '@/analytics/participant'
import { http, endpoints } from '@/api'
import { isActive, isReserved } from '@/api/statuses'
import { CARD_TYPES, SURVEY_BUILDER_APP_KEY } from '@/constants'
import pendo from '@/integrations/pendo'
import rollbar from '@/integrations/rollbar'
import rum from '@/integrations/rum'
import router from '@/router'
import { isStudyCompatible } from '@/store/modules/participant/utils'
import {
  sortByDate,
  sortByNumber,
  getStudyValueForSortOption,
} from '@/utils/sort'
import { localStorageSetItem } from '@/utils/storage'
import { getPreferredSort, sortTrackMapping } from '@/utils/studies'
import { isStudyMultiSubmission } from '@/utils/submissions-config'
import common, { types as commonTypes } from '../../common'

export const types = {
  ADD_PUSHER_CHANNEL: 'ADD_PUSHER_CHANNEL',
  RESET_PUSHER_CHANNELS: 'RESET_PUSHER_CHANNELS',
  SET_SELECTED_STUDY: 'SET_SELECTED_STUDY',
  SET_SHOW_VERIFICATION_CARD_DETAILS: 'SET_SHOW_VERIFICATION_CARD_DETAILS',
  SET_SHOW_WELCOME_PROMPT: 'SET_SHOW_WELCOME_PROMPT',
  SET_QUESTIONS_CATEGORY: 'SET_QUESTIONS_CATEGORY',
  SET_ACTIVE_SORT: 'SET_ACTIVE_SORT',
}

const state = {
  // TODO: it might be cleaner to split this store into state for /studies and /studies/:id at some point
  // state for /studies view
  studiesList: [],
  studies: {},
  // active sort
  activeSort: getPreferredSort(),
  selectedStudy: {},
  questionsCategory: {},
  showVerificationCardDetails: false,
  showWelcomePrompt: false,

  // state for /studies/:id view
  study: {},
  // TODO: this looks unused now. Cleanup in separate PR
  pusherChannels: [],
}

const mutations = {
  [types.ADD_PUSHER_CHANNEL](state, channel) {
    state.pusherChannels.push(channel)
  },

  [types.RESET_PUSHER_CHANNELS](state) {
    state.pusherChannels = []
  },

  [types.SET_SELECTED_STUDY](state, study) {
    state.selectedStudy = study
  },

  [types.SET_SHOW_VERIFICATION_CARD_DETAILS](state, status) {
    state.showVerificationCardDetails = status
  },

  [types.SET_SHOW_WELCOME_PROMPT](state, status) {
    state.showWelcomePrompt = status
  },

  [types.SET_QUESTIONS_CATEGORY](state, questionsCategory) {
    state.questionsCategory = questionsCategory
  },

  [types.SET_ACTIVE_SORT](state, newSort) {
    state.activeSort = newSort
  },
}

const actions = {
  clearSelectedCard({ commit }) {
    commit(types.SET_SELECTED_STUDY, {})
    commit(types.SET_QUESTIONS_CATEGORY, {})
    commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
    commit(types.SET_SHOW_WELCOME_PROMPT, false)
  },

  fetchList(
    { dispatch, commit, state, getters, rootGetters },
    { automatic } = {}
  ) {
    return http
      .get(endpoints.STUDIES_PARTICIPANT, { useGlobal429ErrorHandling: false })
      .then(data => {
        commit(commonTypes.SET_LIST, data.results)

        const displayStudies = getters?.displayStudiesList
        const activeOngoingStudies = getters?.activeOngoingStudies
        const visibleStudies = getters?.visibleStudies
        const studiesExist =
          displayStudies?.length > 0 || activeOngoingStudies?.length > 0
        const isDesktop = rootGetters['global/isMediumWidthAndUp']

        const {
          selectedStudy,
          questionsCategory,
          studies,
          showVerificationCardDetails,
          showWelcomePrompt,
        } = state

        trackStudyDashboardLoad(visibleStudies?.length || 0, automatic ?? false)

        // TODO: Move card selection logic to a separate action, that can be called from multiple places
        // Deselect active study if it has fallen off the list
        if (selectedStudy?.id && !studies[selectedStudy?.id]) {
          // If on desktop, select the first study if there are studies
          if (isDesktop && studiesExist) {
            dispatch('setSingle', {
              type: CARD_TYPES.STUDY,
              item: activeOngoingStudies?.length
                ? activeOngoingStudies[0]
                : displayStudies[0],
              automatic: true,
            })
          } else {
            return dispatch('clearSelectedCard')
          }
        }

        // When the studies list is updated, select the first study, only on desktop, if there is no card selected
        if (
          !selectedStudy?.id &&
          !questionsCategory?.id &&
          !showVerificationCardDetails &&
          !showWelcomePrompt
        ) {
          if (isDesktop && studiesExist) {
            return dispatch('setSingle', {
              type: CARD_TYPES.STUDY,
              item: activeOngoingStudies?.length
                ? activeOngoingStudies[0]
                : displayStudies[0],
              automatic: true,
            })
          } else {
            return dispatch('clearSelectedCard')
          }
        }

        // if the selected study is a ongoing study, use the list item data instead
        // of the full study data (since we need study.submission_started_at)
        const study = activeOngoingStudies?.find(
          ({ id }) => id === selectedStudy.id
        )
        if (study) {
          dispatch('setSingle', {
            type: CARD_TYPES.STUDY,
            item: study,
            automatic: true,
          })
        }

        if (typeof studies === 'object') {
          rum.setGlobalContextProperty(
            'studies_count',
            Object.keys(studies).length
          )
        }
      })
      .catch(async error => {
        rollbar.error(error)

        if (error?.response?.status === 429) {
          const studiesPath = router.resolve({ name: 'studies' }).href
          await router.push({
            name: 'error.participant-studies-rate-limited',
          })

          // keep url as '/studies' rather than '/429' so that
          // the user can refresh the page and try again
          history.replaceState(null, '', studiesPath)
        }
      })
  },

  setActiveSort({ commit, dispatch, getters, rootGetters }, newSort) {
    commit(types.SET_ACTIVE_SORT, newSort)
    localStorageSetItem('preferredSort', newSort)

    const displayStudies = getters.displayStudiesList
    const isDesktop = rootGetters['global/isMediumWidthAndUp']

    // Select the 1st study after sorting change, if the user is on desktop
    if (isDesktop) {
      dispatch('setSingle', {
        type: CARD_TYPES.STUDY,
        item: displayStudies[0],
        automatic: true,
      })
    }

    pendo.track(PARTICIPANT_STUDIES_CHANGE_SORT, {
      sort: newSort,
    })
  },

  fetchSingle({ commit, dispatch }, id) {
    return http.get(endpoints.STUDY_PARTICIPANT(id)).then(async study => {
      dispatch('participant/surveys/reset', undefined, { root: true })
      dispatch('participant/dataCollectionTool/reset', undefined, {
        root: true,
      })

      if (study.external_app === SURVEY_BUILDER_APP_KEY) {
        await dispatch('participant/surveys/fetch', study.external_id, {
          root: true,
        })
      }

      commit(commonTypes.SET_SINGLE, study)

      let { submission } = study
      const { submissions } = study

      if (Array.isArray(submissions)) {
        if (isStudyMultiSubmission(study)) {
          submission =
            submissions.find(
              sub => isActive(sub.status) || isReserved(sub.status)
            ) ?? {}
        } else {
          submission = submissions[0] ?? {}
        }
      } else if (isEmpty(submission)) {
        submission = {}
      }

      if (!isEmpty(submission)) {
        dispatch('participant/submissions/subscribe', submission, {
          root: true,
        })
      }

      commit('participant/submissions/SET_SINGLE', submission, { root: true })
      return study
    })
  },

  setSingle({ commit, getters, rootState }, { type, item, automatic }) {
    if (type === CARD_TYPES.STUDY) {
      commit(types.SET_SELECTED_STUDY, item)

      const visibleStudies = getters.visibleStudies
      trackStudyDashboardEvent('viewed-study-details', item.id, {
        studies: visibleStudies,
        automatic,
      })

      // Clear the selected questions category and set show verification card details to false
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
    }

    if (type === CARD_TYPES.QUESTIONS) {
      commit(types.SET_QUESTIONS_CATEGORY, item)

      // Clear the selected study and set show verification card details to false
      commit(types.SET_SELECTED_STUDY, {})
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
    }

    if (type === CARD_TYPES.VERIFICATION) {
      commit(types.SET_SHOW_WELCOME_PROMPT, false)
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, true)

      // Clear the selected study and questions category
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SELECTED_STUDY, {})
    }

    if (type === CARD_TYPES.WELCOME) {
      commit(types.SET_SHOW_VERIFICATION_CARD_DETAILS, false)
      commit(types.SET_SHOW_WELCOME_PROMPT, true)

      // Clear the selected study and questions category
      commit(types.SET_QUESTIONS_CATEGORY, {})
      commit(types.SET_SELECTED_STUDY, {})
    }
  },

  dismiss({ dispatch, commit, getters, rootGetters, rootState }, study) {
    const { id } = study
    const isDesktop = rootGetters['global/isMediumWidthAndUp']

    return http.post(endpoints.DISMISS_STUDY(id)).then(() => {
      // Remove the study from the list
      commit(commonTypes.DELETE, id)

      const displayStudies = getters.displayStudiesList
      const visibleStudies = getters.visibleStudies

      trackStudyDashboardEvent('dismissed-study', id, {
        studies: visibleStudies,
      })

      // Select the 1st study after dismissing, if there are studies and the user is on desktop
      if (displayStudies.length > 0 && isDesktop) {
        dispatch('setSingle', {
          type: CARD_TYPES.STUDY,
          item: displayStudies[0],
          automatic: true,
        })
      } else {
        dispatch('clearSelectedCard')
      }
    })
  },

  onActiveOngoingStudyTimeout(
    { dispatch, commit, rootGetters, getters },
    studyId
  ) {
    const study = getters.activeOngoingStudies.find(({ id }) => id === studyId)
    if (!study) {
      return
    }
    dispatch('clearSelectedCard')
    dispatch('participant/submissions/clearActiveOngoingStudy', studyId, {
      root: true,
    })
    commit(commonTypes.DELETE, studyId)
    const displayStudies = getters.displayStudiesList
    const isDesktop = rootGetters['global/isMediumWidthAndUp']
    if (displayStudies.length > 0 && isDesktop) {
      dispatch('setSingle', {
        type: CARD_TYPES.STUDY,
        item: displayStudies[0],
        automatic: true,
      })
    }
  },
}

const getters = {
  // Use on the /studies/:id view,
  // TODO: This looks the same as the `selectedStudy` getter. Check if needed
  denormalized: state => {
    const { study, researcher } = state
    if (!study || !researcher) {
      return {}
    }
    return {
      ...study,
      researcher: researcher[study.researcher],
    }
  },

  isCardSelected:
    state =>
    ({ type, item }) => {
      switch (type) {
        case CARD_TYPES.STUDY:
          return (
            !isEmpty(state.selectedStudy) && item.id === state.selectedStudy.id
          )
        case CARD_TYPES.QUESTIONS:
          return (
            !isEmpty(state.questionsCategory) &&
            item.id === state.questionsCategory.id
          )
        default:
          return false
      }
    },

  isStudySelected: state => !isEmpty(state.selectedStudy),
  selectedStudy: state => state.selectedStudy,
  isQuestionCardSelected: state => !isEmpty(state.questionsCategory),
  questionsCategorySelected: state => state.questionsCategory,
  isVerificationCardSelected: state => state.showVerificationCardDetails,
  isWelcomePromptSelected: state => state.showWelcomePrompt,
  activeSort: ({ activeSort }) => {
    trackStudyDashboardSort(sortTrackMapping[activeSort])
    return activeSort
  },

  // Get all user preferences in an array form for easier filtering
  userPreferences: (_state, getters, _rootState, rootGetters) => {
    const devicePreferences = rootGetters['auth/devicePreferences']
    const contentTypePreferences = rootGetters['auth/contentTypePreferences']
    const studyTypePreferences = rootGetters['auth/studyTypePreferences']

    // Minimum reward filter might not be set, default to 0 if not set
    const userStudyMinRewardPreference =
      rootGetters['auth/dashboardFiltersPreferences']?.minimum_reward ?? 0

    // Get the user's home currency so we can filter by the user's minimum reward on the correct currency
    const participantHomeCurrency = rootGetters['auth/participantHomeCurrency']

    if (
      !devicePreferences ||
      !contentTypePreferences ||
      !studyTypePreferences
    ) {
      return null
    }

    const { device_type_preferences, device_requirement_preferences } =
      devicePreferences

    return {
      userDeviceTypePreferences: Object.keys(device_type_preferences).filter(
        key => device_type_preferences[key]
      ),
      userDeviceRequirementPreferences: Object.keys(
        device_requirement_preferences
      ).filter(key => device_requirement_preferences[key]),
      userContentTypePreferences: Object.keys(contentTypePreferences).filter(
        key => contentTypePreferences[key]
      ),
      userStudyTypePreferences: Object.keys(studyTypePreferences).filter(
        key => studyTypePreferences[key]
      ),
      userStudyMinRewardPreference,
      participantHomeCurrency,
      activeSort: getters.activeSort,
    }
  },

  // Active ongoing studies. These are studies that are currently in progress, they appear at the top of the list and are separate from the rest of the studies
  activeOngoingStudies: (state, _getters, _rootState, rootGetters) => {
    const user = rootGetters['auth/user']

    const activeOngoingStudyIds = user.active_ongoing_study_ids ?? []

    return activeOngoingStudyIds
      .map(id => state.studies[id])
      .filter(study => !!study)
  },

  // All studies except the active ongoing studies, including the ones that might be filtered out
  allStudiesExceptActiveOngoing: (_state, getters, _rootState, rootGetters) => {
    const allStudies = getters.all
    const user = rootGetters['auth/user']
    const activeOngoingStudyIds = user.active_ongoing_study_ids

    return allStudies.filter(
      study =>
        !(study.is_ongoing_study && activeOngoingStudyIds.includes(study.id))
    )
  },

  // The total number of studies the user has access to, including the ones that might be filtered out
  originalStudiesCount: (_state, getters) =>
    getters.allStudiesExceptActiveOngoing.length,

  // Filtered studies list, based on user preferences
  // This list is used to then sort the studies, its not used directly
  filteredStudies: (_state, getters) => {
    // We get all studies besides the active ongoing studies, since those appear at the top of the list in a separate section
    const studies = getters.allStudiesExceptActiveOngoing
    const preferences = getters.userPreferences

    if (!studies || !preferences) {
      return []
    }

    const filteredStudies = studies.filter(study =>
      isStudyCompatible(study, preferences)
    )

    return filteredStudies
  },

  // The normal list of studies, sorted by the user's preferred sort
  // This list does not include pinned studies
  normalSortedStudies: (_state, getters) => {
    const filteredList = getters.filteredStudies

    // Remove pinned studies from the list
    const normalStudies = filteredList.filter(
      study => !study.is_official_prolific_study
    )

    // Get the user's home currency so we can sort by the correct currency on the study rewards
    const { participantHomeCurrency, activeSort } = getters.userPreferences

    // Get preferred sort options and determine the sort function to use
    const [sortValue, sortType, sortDirection] = activeSort.split('|')
    const sortFunction = sortType === 'date' ? sortByDate : sortByNumber

    normalStudies.sort((studyA, studyB) => {
      const studyAValue = getStudyValueForSortOption(
        studyA,
        sortValue,
        participantHomeCurrency
      )

      const studyBValue = getStudyValueForSortOption(
        studyB,
        sortValue,
        participantHomeCurrency
      )

      return sortFunction(studyAValue, studyBValue)
    })

    if (sortDirection === 'descending') {
      normalStudies.reverse()
    }

    return normalStudies
  },

  // Pinned list of studies, sorted by the `display_configuration.pinned_display_order` field
  // This list does not include normal studies
  pinnedSortedStudies: (_state, getters) => {
    const filteredList = getters.filteredStudies

    // Remove studies that are not pinned, and then sort by the pinned_display_order field
    return filteredList
      .filter(study => study.is_official_prolific_study)
      .sort((studyA, studyB) => {
        const studyAValue = studyA?.display_configuration?.pinned_display_order
        const studyBValue = studyB?.display_configuration?.pinned_display_order

        // We want to sort by the pinned_display_order field
        return sortByNumber(studyAValue, studyBValue)
      })
  },

  // The final list of studies to display on the page
  // This list includes both the normal and pinned studies, both filtered and sorted
  displayStudiesList: (_state, getters) => {
    const normalStudies = getters.normalSortedStudies
    const pinnedStudies = getters.pinnedSortedStudies

    // Combine the pinned and normal studies, but show the pinned studies first
    return [...pinnedStudies, ...normalStudies]
  },

  // Studies visibile on the page, including the active ongoing studies
  visibleStudies(_state, getters) {
    return [...getters.activeOngoingStudies, ...getters.displayStudiesList]
  },
}

export default merge(common({ entities: 'studies', entity: 'study' }), {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
})
