export const URL_REUSE = {
  RECIEVED: 'This URL can be used only once',
  REFORMATTED:
    'This link has already been used. Please try again with a new link.',
}
export const GOOGLE_SIGN_UP_ATTEMPT = {
  RECIEVED: 'PEC-GL-0003',
  REFORMATTED:
    'PEC-GL-0003: User not found. Please register via email and password.',
}
export const REFRESHED = {
  RECIEVED:
    "You may have pressed the back button, refreshed during login, opened too many login dialogs, or there is some issue with cookies, since we couldn't find your session. Try logging in again from the application and if the problem persists please contact the administrator.",
  REFORMATTED:
    "You may have pressed the back button, refreshed during login, opened too many login dialogs, or there is some issue with cookies, since we couldn't find your session. Try logging in again and if the problem persists please contact support.",
}
export const RATE_LIMIT = {
  RECIEVED: 'exceeded the rate limit',
  REFORMATTED:
    "You've exceeded the rate limit. Please try again in a short while.",
}
