import type { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'
import modules from './modules'

type GetState<Modules> = {
  [K in keyof Modules]: 'modules' extends keyof Modules[K]
    ? GetState<Modules[K]['modules']>
    : 'state' extends keyof Modules[K]
      ? Modules[K]['state']
      : never
}

type State = GetState<typeof modules>

export const key: InjectionKey<Store<State>> = Symbol.for('store')

const store = createStore<State>({
  strict: import.meta.env['NODE_ENV'] === 'development',
  modules,
})

export function useStore() {
  return baseUseStore(key)
}

export default store
