<template>
  <div
    :class="['layout', isResearcher ? 'researcher' : 'participant']"
    :data-testid="`${isResearcher ? 'researcher' : 'participant'}-layout`"
  >
    <!-- Messages -->
    <p-message
      v-if="isOnHold && (isParticipant || (isResearcher && isEmailVerified))"
      data-testid="on-hold-message"
    >
      {{ $t('account_on_hold') }}
    </p-message>
    <p-message
      v-if="featureIsEnabled(FLAG_ES_DISABLE_CASHOUTS) && isParticipant"
      data-testid="cashout-disabled-message"
    >
      <i18n-t keypath="cashout_disabled">
        <template #cashoutTweet>
          <a
            href="https://twitter.com/Prolific/status/1549430011488337920"
            target="_blank"
          >
            {{ $t('cashout_disabled_twitter_link') }}
          </a>
        </template>
      </i18n-t>
    </p-message>

    <p-message
      v-if="showGlobalRepSampleMessage"
      dismissible
      storage-id="global_rep_sample_message"
      data-testid="global-rep-sample-message"
    >
      <i18n-t keypath="global_rep_sample.message">
        <template #helpCentreLink>
          <a
            href="https://participant-help.prolific.com/hc/en-gb/articles/12930196691100"
            target="_blank"
          >
            {{ $t('global_rep_sample.help_centre_link') }}
          </a>
        </template>
      </i18n-t>
    </p-message>
    <p-message v-if="message" :message="message" />
    <p-message
      v-if="showChristmasSupportMessage"
      storage-id="christmas_support_message_2021"
      data-testid="christmas-support-message"
      dismissible
    >
      <i18n-t keypath="christmas_support">
        <template #helpCentreArticle>
          <a
            :href="
              isResearcher
                ? 'https://researcher-help.prolific.com/hc/en-gb/articles/360017898219'
                : 'https://participant-help.prolific.com/hc/en-gb/articles/360017865040'
            "
            target="_blank"
          >
            {{ $t('help_centre_article') }}
          </a>
        </template>

        <template #christmasTreeEmoji>
          <p-emoji :label="$t('christmas_tree')" emoji="🎄" />
        </template>
      </i18n-t>
    </p-message>
    <p-message
      v-else-if="showEasterSupportMessage"
      storage-id="easter_support_message_2022"
      data-testid="easter-support-message"
      dismissible
    >
      <i18n-t keypath="easter_support">
        <template #easterReadMore>
          <a
            :href="
              BLOG_BASE_URL +
              '/prolific-is-closing-for-an-extended-easter-break'
            "
            target="_blank"
          >
            {{ $t('read_more') }}
          </a>
        </template>

        <template #easterBunnyEmoji>
          <p-emoji :label="$t('easter_bunny')" emoji="🐰" />
        </template>
      </i18n-t>
    </p-message>

    <p-message
      v-if="
        shouldShowReferralCouponExpiry &&
        !showEasterSupportMessage &&
        !showChristmasSupportMessage
      "
      data-testid="coupon-reminder-message"
      :storage-id="currentReferralCouponMessageKey"
      :type="isCurrentReferralCouponExpiring ? 'warning' : 'success'"
      dismissible
      @dismiss="handleDismissCouponMessage"
      @message-dismissed="handleCouponTrackingOnMount"
    >
      {{
        isCurrentReferralCouponExpiring
          ? $t('referral_coupon_reminder.within_seven_days', {
              expiryDate: currentReferralCouponExpiryDate,
            })
          : $t('referral_coupon_reminder.more_than_seven_days', {
              couponAmount: currentReferralCouponAmount,
              minTopup: minTopup,
            })
      }}
    </p-message>

    <!-- Transition -->
    <transition v-if="!hideNav" name="slideDown" mode="out-in">
      <component
        :is="isResearcher ? 'p-researcher-nav' : 'p-participant-nav'"
        :show-main-nav-links="!hideNavLinks"
        @loaded="loaded = true"
      />
    </transition>

    <!-- Router view -->
    <!-- If hideNav is true, render the view too -->
    <div v-if="loaded || hideNav" class="content-wrap">
      <div :class="{ 'is-flex': isFlex }" class="content">
        <AccountProtectionBanner v-if="shouldShowAccountProtectionBanner" />
        <router-view />
      </div>
    </div>

    <!-- Modals -->
    <DSDialog
      v-model:open="showVerificationDialog"
      :show-close-button="true"
      :should-close-on-outside-click="false"
      @close="handleClose"
    >
      <template #default="panelProps">
        <DSDialogPanel v-bind="panelProps" class="dialog-panel">
          <template #header="{ id }">
            <h2 :id="id" class="account-dialog-title">
              {{ $t('verification_dialog.title') }}
            </h2>
          </template>
          <p>
            {{
              $t('verification_dialog.description', {
                email: email,
              })
            }}
          </p>

          <div class="account-dialog-buttons">
            <DSButton @click="resendEmailVerificationClick">
              {{ $t('resend_email') }}
            </DSButton>
            <DSButton
              :href="
                $router.resolve({
                  name: 'account.general',
                }).path
              "
              variant="secondary"
            >
              {{ $t('change_email') }}
            </DSButton>
          </div>
        </DSDialogPanel>
      </template>
    </DSDialog>
    <DSDialog
      v-model:open="showBlockedDialog"
      :show-close-button="true"
      :should-close-on-outside-click="false"
    >
      <template #default="panelProps">
        <DSDialogPanel v-bind="panelProps" class="dialog-panel">
          <template #header="{ id }">
            <h2 :id="id" class="account-dialog-title">
              {{ $t('blocked_dialog.title') }}
            </h2>
          </template>
          <p>
            {{
              $t('blocked_dialog.description', {
                email: email,
              })
            }}
          </p>

          <div class="account-dialog-buttons">
            <DSButton
              :href="
                $router.resolve({
                  name: 'account.general',
                }).path
              "
            >
              {{ $t('update_account') }}
            </DSButton>
          </div>
        </DSDialogPanel>
      </template>
    </DSDialog>
  </div>
</template>

<i18n lang="yaml">
en:
  account_on_hold: Your account has been placed on hold. Please contact support for further information.
  global_rep_sample:
    message: To be paid for your Prolific studies, you must set up a Wise account with your Prolific email address. {helpCentreLink}.
    help_centre_link: Learn more
  christmas_support: We’ll have reduced capacity in our support team over the Christmas holidays - take a read of our {helpCentreArticle} for all the details {christmasTreeEmoji}
  christmas_tree: Christmas tree
  easter_support: Over the extended Easter break between 13th and 20th April 2022, our support team has reduced capacity and may take longer than usual to respond to requests. {easterReadMore} {easterBunnyEmoji}
  read_more: Read more on our blog
  easter_bunny: Easter bunny
  help_centre_article: Help Centre article
  cashout_disabled: Cashouts are temporarily disabled while we investigate an ongoing issue. You can read more about this {cashoutTweet}.
  cashout_disabled_twitter_link: on our Twitter status update
  resend_email: Resend email
  update_account: Update account
  change_email: Change email address
  notifications:
    email_verification_sent_title: Verification email sent
    email_verification_sent_description: Your email was sent to {email}
  verification_dialog:
    title: Verify your email address
    description: We've sent a verification link to {email}. Please complete the verification to continue.
  blocked_dialog:
    title: Account on hold
    description: Prolific only accepts work or institutional email addresses for security reasons. If you have signed up with a personal address please update your email address.
  referral_coupon_reminder:
    within_seven_days: Your free credits are expiring soon! Use them before the {expiryDate} to get the most out of Prolific!
    more_than_seven_days: Your free credits of {couponAmount} will be automatically applied when you add {minTopup} or more to your account.
  en-x-ai:
    researcher_email_not_verified_pending_approval: Welcome! Take a look around and set up a task. Your account requires approval to add money or publish a task. Verify your email address and Prolific will review your account.
    researcher_email_not_verified_approved: You've been approved by Prolific. Verify your email address to add money or publish a task.
    researcher_email_verified_pending_approval: You've successfully verified your email. Your account requires approval to add money or publish a task. Prolific reviews new accounts on a regular basis.
</i18n>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
import { DSButton, DSDialog, DSDialogPanel } from '@prolific-oss/design-system'
import { defineAsyncComponent } from 'vue'
import { mapGetters, mapState, mapActions } from 'vuex'
import {
  trackViewCouponExpiringAlert,
  trackDismissCouponExpiringAlert,
  trackViewNewCouponAlert,
  trackDismissNewCouponAlert,
} from '@/analytics/researcher'
import Emoji from '@/components/common/Emoji.vue'
import Image from '@/components/common/Image.vue'
import { AccountProtectionBanner } from '@/components/mfa'
import Message from '@/components/nav/main/Message.vue'
import { BLOG_BASE_URL } from '@/constants'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import PFeatureToggle from '@/integrations/launchDarkly/FeatureToggle'
import {
  FLAG_ES_DISABLE_CASHOUTS,
  FLAG_SSG_ONBOARDING_STEPS,
} from '@/integrations/launchDarkly/active-flags'
import { todayIsBetween, formatMoneyWithCurrencySymbol } from '@/utils'
import { checkIfMessagePreviouslyDismissed } from '@/utils/dismissed_messages'
import { getOrdinalSuffix } from '@/utils/getOrdinalDateSuffix'
import notifier from '@/utils/notifier'
import { sessionStorageGetItem } from '@/utils/storage'

const ParticipantNav = defineAsyncComponent(
  () => import('@/components/nav/main/participant/Participant.vue')
)

const ResearcherNav = defineAsyncComponent(
  () => import('@/components/nav/main/researcher/Researcher.vue')
)

export default {
  name: 'PLayoutAuthenticated',

  components: {
    DSButton,
    DSDialog,
    DSDialogPanel,
    'p-participant-nav': ParticipantNav,
    'p-researcher-nav': ResearcherNav,
    'p-message': Message,
    'p-emoji': Emoji,
    'p-image': Image,
    'p-feature-toggle': PFeatureToggle,
    AccountProtectionBanner,
  },

  data() {
    return {
      loaded: false,
      showVerificationDialog: false,
      showBlockedDialog: false,
      FLAG_ES_DISABLE_CASHOUTS,
      BLOG_BASE_URL,
    }
  },

  computed: {
    ...mapState({
      message: state => state.global.message,
      isFlex: state => state.global.contentIsFlex,
    }),

    ...mapGetters('auth', [
      'isResearcher',
      'isParticipant',
      'isOnHold',
      'isEmailVerified',
      'isPendingApproval',
      'hasNoPayPalLinked',
      'hasPayPalLinkedWithMissingDetails',
      'email',
      'triggerVerificationDialog',
      'isGlobalRepSampleCountry',
      'user',
    ]),

    showChristmasSupportMessage() {
      return todayIsBetween('2021-12-23', '2022-01-04')
    },

    showEasterSupportMessage() {
      return todayIsBetween('2022-04-13', '2022-04-20')
    },

    showGlobalRepSampleMessage() {
      const globalRepSampleMessageDismissed = checkIfMessagePreviouslyDismissed(
        'global_rep_sample_message',
        'local'
      )

      return (
        this.isParticipant &&
        this.isGlobalRepSampleCountry &&
        !globalRepSampleMessageDismissed
      )
    },

    routeName() {
      return this.$route.name
    },

    hideNav() {
      return this.$route.meta.hideNav
    },

    hideNavLinks() {
      return this.$route.meta.hideNavLinks
    },

    shouldShowVerificationDialog() {
      if (featureIsEnabled(FLAG_SSG_ONBOARDING_STEPS)) return false

      return (
        this.isResearcher &&
        !this.isEmailVerified &&
        !this.isPendingApproval &&
        this.routeName !== 'account.general'
      )
    },

    shouldShowBlockedDialog() {
      return (
        this.isResearcher &&
        this.isPendingApproval &&
        this.routeName !== 'account.general'
      )
    },

    currentUndismissedReferralCoupon() {
      return this.user.redeemable_referral_coupons?.find(
        coupon =>
          !checkIfMessagePreviouslyDismissed(
            this.getReferralCouponMessageKey(coupon)
          )
      )
    },

    currentReferralCouponMessageKey() {
      return this.getReferralCouponMessageKey(
        this.currentUndismissedReferralCoupon
      )
    },

    isCurrentReferralCouponExpiring() {
      if (!this.currentUndismissedReferralCoupon) return false

      return this.isReferralCouponExpiringInSevenDays(
        this.currentUndismissedReferralCoupon
      )
    },

    shouldShowReferralCouponExpiry() {
      if (this.isParticipant) return false
      const expiryDate = this.currentReferralCouponExpiryDate
      if (!expiryDate) return false

      return this.isResearcher && this.currentUndismissedReferralCoupon
    },

    currentReferralCouponExpiryDate() {
      if (!this.currentUndismissedReferralCoupon) return null

      const expiryToDate = new Date(
        this.currentUndismissedReferralCoupon.expiry_date
      ).getDate()
      return `${expiryToDate}${getOrdinalSuffix(expiryToDate)}`
    },

    currentReferralCouponAmount() {
      if (!this.currentUndismissedReferralCoupon) return null

      const couponAmount =
        this.currentUndismissedReferralCoupon.recipient_amount ?? 0

      const currency =
        this.currentUndismissedReferralCoupon.recipient_currency_code

      return formatMoneyWithCurrencySymbol(currency, couponAmount, 2)
    },

    minTopup() {
      return formatMoneyWithCurrencySymbol(
        this.currentUndismissedReferralCoupon.recipient_currency_code,
        this.currentUndismissedReferralCoupon.minimum_topup,
        2
      )
    },

    /**
     * Determines whether to show the account protection banner.
     * @returns {boolean} True if the banner should be shown, false otherwise.
     *
     * This function checks the current route to decide if the account protection
     * banner should be displayed. We avoid showing the banner on certain paths:
     *
     * 1. 'workspaces' and 'home': These routes have a side navigation
     *    which changes the CSS/positioning requirements. In these cases,
     *    the Workspaces.vue component handles rendering the banner instead.
     *
     * 2. 'account': It doesn't make sense to show the banner here because
     *    this is where users set up their MFA configuration.
     *
     * For all other routes, the banner will be displayed.
     */
    shouldShowAccountProtectionBanner() {
      const pathsToCheck = ['workspaces', 'account']
      return !pathsToCheck.some(path => this.$route.fullPath.includes(path))
    },
  },

  watch: {
    triggerVerificationDialog: {
      handler() {
        this.showVerificationDialog = this.triggerVerificationDialog
      },

      immediate: true,
    },

    shouldShowVerificationDialog: {
      handler() {
        this.showVerificationDialog = this.shouldShowVerificationDialog
      },

      immediate: true,
    },

    shouldShowBlockedDialog: {
      handler() {
        this.showBlockedDialog = this.shouldShowBlockedDialog
      },

      immediate: true,
    },
  },

  mounted() {
    if (this.isParticipant) {
      this.watchForPAEnabled()
      this.checkIfHyperwalletOnboardingModalShouldBeShown()
      this.$store.dispatch(
        'participant/payments/fetchCashoutStatus',
        this?.user?.id
      )
    }
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect()
    }
  },

  methods: {
    ...mapActions('auth', [
      'setPAEnabled',
      'resendEmailVerification',
      'triggerVerificationDialogAction',
    ]),

    ...mapActions('global', ['showHyperwalletOnboardingModal']),

    featureIsEnabled,

    checkIfHyperwalletOnboardingModalShouldBeShown() {
      const setUpHyperwalletModalDismissed = sessionStorageGetItem(
        'setUpHyperwalletModalDismissed'
      )
      if (setUpHyperwalletModalDismissed === 'true') return
      if (
        this.hasPayPalLinkedWithMissingDetails ||
        (this.hasNoPayPalLinked && this.$route.name === 'account.general')
      ) {
        this.showHyperwalletOnboardingModal(true)
      }
    },

    handleClose() {
      this.triggerVerificationDialogAction(false)
    },

    watchForPAEnabled() {
      if (this.observer) {
        this.observer.disconnect()
      }
      const targetNode = document.getElementById('app')
      if (!targetNode) {
        return
      }
      const setEnabled = () =>
        this.setPAEnabled(targetNode.classList.contains('pa-enabled'))

      setEnabled()
      const config = { attributes: true, childList: false, subtree: false }
      const callback = function (mutationsList, observer) {
        for (const mutation of mutationsList) {
          if (
            mutation.type === 'attributes' &&
            mutation.attributeName == 'class'
          ) {
            setEnabled()
          }
        }
      }
      this.observer = new MutationObserver(callback)
      this.observer.observe(targetNode, config)
    },

    resendEmailVerificationClick() {
      this.resendEmailVerification().then(() => {
        notifier.customSuccess({
          title: this.$t('notifications.email_verification_sent_title'),
          message: this.$t(
            'notifications.email_verification_sent_description',
            {
              email: this.email,
            }
          ),
          duration: 0,
        })
      })
    },

    isReferralCouponExpiringInSevenDays(coupon) {
      const currentDate = new Date()
      const expiryDateTime = new Date(coupon.expiry_date)
      // get the different in milliseconds
      const diffTime = Math.abs(expiryDateTime - currentDate)
      // convert milliseconds to days
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      return this.isResearcher && diffDays <= 7
    },

    getReferralCouponMessageKey(coupon) {
      const prefix = this.isReferralCouponExpiringInSevenDays(coupon)
        ? 'week_before'
        : 'new'
      return `${prefix}_coupon_reminder_message_${this.user.id}_${coupon.referral_code}`
    },

    handleDismissCouponMessage() {
      if (this.isCurrentReferralCouponExpiring) {
        trackDismissCouponExpiringAlert()
      } else {
        trackDismissNewCouponAlert()
      }
    },

    handleCouponTrackingOnMount(dismissed) {
      if (dismissed || !this.shouldShowReferralCouponExpiry) {
        return
      }
      if (this.isCurrentReferralCouponExpiring) {
        trackViewCouponExpiringAlert()
      } else {
        trackViewNewCouponAlert()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 0; // for FF
  position: relative;
}

.content-wrap {
  margin-top: 0;
  display: flex;
  flex: 1 1 auto;
  min-height: 0;
}

.content {
  display: flex;
  flex-direction: column;
  text-align: left;
  overflow-y: auto;
  flex: 1;
  background-color: $background-color-softer;

  > * {
    min-height: 100%;
  }

  &.is-flex {
    display: flex;
    flex-direction: column;
  }
}

.slideDown-enter-active,
.slideDown-leave-active {
  transition: all 0.5s ease;
}

.slideDown-enter-from,
.slideDown-leave-active {
  transform: translateY(-100px);
}

.slideRight-enter-active,
.slideRight-leave-active {
  transition: all 0.5s ease;
}

.slideRight-enter-from,
.slideRight-leave-active {
  transform: translateX(-300px);
}

.resend-email-button {
  margin-left: $space-2;
}

.account-dialog-title {
  font-weight: $font-weight-bold;
  font-size: $font-size-4;
}

.account-dialog-buttons {
  display: flex;
  flex-flow: row wrap;
  gap: $space-3;
  margin-top: $space-4;
}

.confetti {
  position: absolute;
  pointer-events: none;
  height: 100%;
  width: 100%;
  z-index: 9999;
}
</style>

<!--
  We are ignoring this lint rule because we need to target elements which sit outside
  the scope of the root element of this component. These styles will have global
  scope in the app should named with caution.
-->
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style>
.grecaptcha-badge {
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
}
</style>
