import type { RouteLocationNormalized, NavigationGuardNext } from 'vue-router'
import store from '@/store'

const Messages = () => import('@/views/messages/Messages.vue')

// don't allow banned users to access
// messaging due to abuse
// don't allow non-researchers to access
// this messaging tab
const beforeEnter = async (
  _to: RouteLocationNormalized,
  _from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (store.getters['auth/isBanned'] || !store.getters['auth/isResearcher']) {
    return next({ name: 'home' })
  }
  try {
    await store.state.messages.loginPromise
  } catch (_error) {
    // firebase login failed
    return next({ name: 'error.500' })
  }
  next()
}

export default [
  {
    path: 'researcher/workspaces/:workspaceId/messages',
    name: 'workspaces.messages',
    component: Messages,
    beforeEnter,
  },
  {
    path: 'researcher/workspaces/:workspaceId/messages/:channel',
    name: 'workspaces.messages.channel',
    component: Messages,
    beforeEnter,
  },
]
