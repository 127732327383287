/* eslint no-console: "warn" */
/* eslint-disable no-console */
// my log does not judge
const isEnabled = import.meta.env.VUE_APP_MARGARET_LANTERMAN === 'on'

export default {
  isEnabled,
  log(...args) {
    if (!isEnabled) {
      return
    }
    console.log(...args)
  },
  error(...args) {
    if (!isEnabled) {
      return
    }
    console.error(...args)
  },
}
