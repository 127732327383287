<template>
  <p-progress-bar
    v-if="
      !shouldShowResearcherLoading && !$route.path.includes('oauth/callback')
    "
  />

  <p-message v-if="incident" :message="incident" type="error" />
  <template v-if="isSiteInMaintenanceMode">
    <p-error :code="503" />
  </template>
  <template v-else-if="error">
    <p-error :code="error" />
  </template>
  <template v-else-if="checkUserBanned">
    <p-error code="banned" />
  </template>
  <template v-else-if="shouldShowResearcherLoading">
    <LogoLoading />
  </template>

  <template v-else>
    <router-view />
    <!-- TODO: Display app version number in non-prod environments to help debugging -->
  </template>

  <p-privacy-policy-modal v-if="notAcceptedPrivacyPolicy" />
  <p-terms-and-conditions-modal v-else-if="notAcceptedTermsAndConditions" />
</template>

<i18n lang="yaml">
en:
  new_version_title: A new version of Prolific is available
</i18n>

<!-- TODO: Migrate to <script setup lang="ts"> -->
<!-- eslint-disable vue/component-api-style -->
<!-- eslint-disable vue/block-lang -->
<script>
// TODO: refactor and re-enable rule

/* eslint-disable vue/one-component-per-file */
import debounce from 'lodash/debounce'
import { defineAsyncComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'
import LogoLoading from '@/components/common/LogoLoading.vue'
import Message from '@/components/nav/main/Message.vue'
import ProgressBar from '@/components/nav/main/ProgressBar.vue'
import UpdateNotificationModal from '@/components/notifications/UpdateNotificationModal.vue'
import { featureIsEnabled } from '@/integrations/launchDarkly'
import { FLAG_ES_MAINTENANCE_MODE } from '@/integrations/launchDarkly/active-flags'
import notifier from '@/utils/notifier'

const ErrorView = defineAsyncComponent(() => import('@/views/error/Error.vue'))
const PrivacyPolicyModal = defineAsyncComponent(
  () => import('@/components/account/PrivacyPolicyModal.vue')
)
const TermsAndConditionsModal = defineAsyncComponent(
  () => import('@/components/account/TermsAndConditionsModal.vue')
)

export default {
  name: 'PApp',

  components: {
    'p-error': ErrorView,
    'p-progress-bar': ProgressBar,
    'p-privacy-policy-modal': PrivacyPolicyModal,
    'p-terms-and-conditions-modal': TermsAndConditionsModal,
    'p-message': Message,
    LogoLoading,
  },

  computed: {
    ...mapState({
      error: state => state.global.error,
      maintenance: state =>
        import.meta.env.VUE_APP_MODE === 'e2e'
          ? false
          : state.global.maintenance,
      incident: state => state.global.incident,
      longLoading: state => state.global.longLoading,
      user: state => state.auth.user,
    }),

    ...mapGetters('auth', [
      'notAcceptedPrivacyPolicy',
      'notAcceptedTermsAndConditions',
    ]),

    ...mapGetters('researcher/workspaces', ['walletInPaymentsBeta']),

    isSiteInMaintenanceMode() {
      return featureIsEnabled(FLAG_ES_MAINTENANCE_MODE) || this.maintenance
    },

    shouldShowResearcherLoading() {
      return (
        this.longLoading &&
        (this.user?.user_type === 'Researcher' || !this.user)
      )
    },

    checkUserBanned() {
      return (
        this.user?.user_type === 'Researcher' && this.user?.status === 'BANNED'
      )
    },
  },

  watch: {
    // TODO: Remove this after full release of payments
    walletInPaymentsBeta(newValue, oldValue) {
      // only show the update notification on beta status change
      // not on initial app load
      if (oldValue !== undefined && newValue !== oldValue) {
        this.generateUpdateNotificationNotifier()
      }
    },
  },

  mounted() {
    if (!(this.$route && this.$route.meta.isPublic)) {
      this.pollForVersionChange()
    }
    this.setLongLoading(true)
    window.addEventListener('resize', this.resizeEventHandler)
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.resizeEventHandler)
  },

  methods: {
    ...mapActions('global', [
      'checkVersion',
      'setClientWidth',
      'setLongLoading',
    ]),

    generateUpdateNotificationNotifier(interval) {
      notifier.component(UpdateNotificationModal, {
        title: this.$t('new_version_title'),
        duration: 0,
        showClose: false,
        type: 'info',
        on: {
          later: notification => {
            notification.close()
            if (interval) {
              clearInterval(interval)
            }
          },
          refresh: () => {
            window.location.reload(true) // true ensures that the page is not loaded from the cache
          },
        },
      })
    },

    pollForVersionChange() {
      const POLL_INTERVAL = 600000 // ten min
      const interval = setInterval(() => {
        this.checkVersion().then(newVersionAvailable => {
          if (newVersionAvailable) {
            // don't keep polling if already displaying notification
            clearInterval(interval)
            this.generateUpdateNotificationNotifier(interval)
          }
        })
      }, POLL_INTERVAL)
    },

    resizeEventHandler: debounce(
      function () {
        this.setClientWidth(window.innerWidth)
      },
      50,
      { trailing: true }
    ),
  },
}
</script>

<!--
  We are ignoring this lint rule because we need to target elements which sit outside
  the scope of the root element of this component. These styles will have global
  scope in the app should named with caution.
-->
<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
</style>
