import isEmpty from 'lodash/isEmpty'
import { featureIsEnabled } from '@/integrations/launchDarkly'

const extractFeatureFlagsFromRoutes = featureFlaggedRoutes => {
  const featureFlags = []
  featureFlaggedRoutes.forEach(route => {
    const {
      meta: { requiresLDFlag: flags },
    } = route
    featureFlags.push(...(Array.isArray(flags) ? flags : [flags]))
  })
  return featureFlags
}

/**
 * Protect routes by requiring an LD flag to be true.
 * Warning: this will await the launchDarkly init so should be
 *   used carefully.
 * To use, just add meta: {requiresLDFlag: 'Your_LD_Flag_String'} to
 * the route config.
 * Note: using `requiresLDFlag` with workspaces-targeting is currently unsupported
 *   as the workspace does not get set up immediately on page load
 */
export const routeUnderDevelopment = async (to, store) => {
  const { matched } = to
  if (isEmpty(matched)) {
    return false
  }
  const featureFlaggedRoutes = matched.filter(
    match => match.meta?.requiresLDFlag
  )
  if (isEmpty(featureFlaggedRoutes)) {
    return false
  }

  const extractedFeatureFlags =
    extractFeatureFlagsFromRoutes(featureFlaggedRoutes)
  await store.state?.auth?.launchDarklySetUserPromise
  const allFlagsEnabled = extractedFeatureFlags.every(flag =>
    featureIsEnabled(flag)
  )
  return !allFlagsEnabled
}
