import type { StudyWithSubmissionsConfig } from '@/utils/submissions-config'

/**
 * Sets a submissions_config field on a study. Updates the study in place.
 */
export const setSubmissionsConfigField = <
  FieldName extends
    keyof Required<StudyWithSubmissionsConfig>['submissions_config'],
>(
  study: StudyWithSubmissionsConfig,
  field: FieldName,
  value: Required<StudyWithSubmissionsConfig>['submissions_config'][FieldName]
) => {
  study.submissions_config = study.submissions_config ?? {}
  study.submissions_config[field] = value
}
